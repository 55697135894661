import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import useDetails from "../../hooks/useDetails";
import api from "../../api/api";
import usePayments from "../../hooks/usePayments";
import { toast } from "react-toastify";
import StudentInfo from "../../components/StudentInfo";
import { LuArrowRight, LuArrowUpRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { FiHelpCircle } from "react-icons/fi";

const Accomodation = () => {
  const [selectedRoom, setSelectedRoom] = useState("");
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState();
  const [btn, setBtn] = useState("Book");

  const { data: details } = useDetails();
  const { payment } = usePayments();

  const navigate = useNavigate();

  //TODO: [DSPG-76] Configuring payment check before accommodation payment
  const schoolFee = details?.payment_complete;

  const getRooms = async () => {
    try {
      const { data } = await api.get("/hostel/rooms");
      console.log("available rooms", data);
      setRooms(data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const applyForAccomodation = async (e) => {
    e.preventDefault();
    if (schoolFee !== "full" && schoolFee !== "part")
      return toast.warning("Pay your school fee first");
    setLoading(true);
    setBtn("Loading...");
    try {
      const { data } = await api.post("/hostel/select-accommodation", {
        room_type: selectedRoom,
      });
      console.log("Hostel response", data.data);
      const { status, authorization_url } = data.data;
      if (status && status === "success") {
        toast.info("Hostel Payment Already Made");
        setLoading(false);
        setBtn("Book");
      } else {
        toast.success("Initializing Payment");
        setLoading(false);
        setBtn("Book");
        window.location.replace(data?.data?.authorization_url);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBtn("Failed, try again!");
    }
  };

  useEffect(() => {
    getRooms();
  }, []);

  return (
    <div className="">
      {details && (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h1 className="text-2xl font-bold text-brand-darkGray">
                Hostel Accommodation{" "}
              </h1>
              <p className="text-sm text-gray-400">
                Conduct all other payments
              </p>
            </div>

            <button
              className="bg-[#5D71C0] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
              // onClick={() => navigate("/biodata/profile")}
            >
              <p className="text-sm font-medium text-white">
                Accommodation data
              </p>
              <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
                <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
              </div>
            </button>
          </div>

          {/* {schoolFee !== "full" && schoolFee !== "part" && ( */}
          <div className="w-full md:w-3/4 px-4 py-3 bg-[#FFF2B82E] border border-[#7168412B] text-sm font-semibold text-[#4A4117CC] rounded-xl flex items-center gap-3">
            <FiHelpCircle className="size-5 text-[#DACA84] flex-none" />
            You have to pay your School Fees to be eligible for Accommodation
          </div>
          {/* // )} */}

          <div className="bg-white rounded-xl card-body">
            <div className="space-y-4">
              <h5 className="text-lg font-semibold text-brand-darkGray">
                &#x2022; Available accommodation
              </h5>
              {/* <StudentInfo data={details} /> */}
              <form onSubmit={applyForAccomodation}>
                <div className="mb-3">
                  <div className="">
                    <table className="mb-5 bg-gray-100">
                      <th className="">Name of Accomodation</th>
                      <th className="">Number of Occupants</th>
                      <th className="">Amount</th>
                      <tbody>
                        {rooms?.map((room) => (
                          <tr key={room?.id}>
                            <td>{room?.name}</td>
                            <td>{room?.num_of_occupants}</td>
                            <td>₦{room?.amount?.toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <label className="text-sm font-medium text-brand-darkGray">Select the Payment you want to make</label>
                    <select
                      className="form-control w-full md:w-1/3"
                      value={selectedRoom}
                      onChange={(e) => setSelectedRoom(e.target.value)}
                      required
                    >
                      <option value="">--Select Booking--</option>
                      {rooms?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="">
                  <button
                    className="bg-[#5D71C0] py-3 px-4 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out text-white text-sm font-bold"
                    type="submit"
                    disabled={loading}
                  >
                    {btn}
                    <LuArrowRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accomodation;
