const Settings = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-brand-darkGray">Settting</h1>
          {/* <p className="text-sm text-gray-400">Result Publishing Page</p> */}
        </div>
      </div>

      <div className="mt-6 card-body rounded-xl bg-white space-y-10">
        <h5 className="text-lg font-semibold text-brand-darkGray">
          &#x2022; Setting Page
        </h5>
      </div>
    </div>
  );
};
export default Settings;
