import React, { useState } from "react";
import useDetails from "../../../hooks/useDetails";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { button } from "primereact/button";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import Footer from "../../../components/Footer";
import api from "../../../api/api";
import { getCurrentUser } from "../../../utils/authService";
import { useEffect } from "react";
import moment from "moment";
import { VerifyPayments } from "../../../api/student";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllPayments = () => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [payment, setPayment] = useState([]);
  const [successShow, setSuccessShow] = useState(false);
  const { data: details } = useDetails();
  const user = getCurrentUser();
  const navigate = useNavigate();

  const getAllPayment = async () => {
    try {
      const { data } = await api.get(`/student/payment-info/${user}`);
      setPayment(data.data.schoolFeesPayment);
      console.log(data.data.schoolFeesPayment);
    } catch (error) {
      console.log(error);
    }
  };

  const paymentRequery = async (ref) => {
    if (!ref) return alert("No Payment Reference Found");
    try {
      setLoading(true);
      const response = await VerifyPayments(ref);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Verification Failed");
      setLoading(false);
    }
  };

  const formatCurrency = (value) => {
    return "₦" + value;
  };

  const priceBodyTemplate = (product) => {
    return formatCurrency(product.amount_paid);
  };

  const paymentFormat = (payment) => {
    return moment(payment?.generated_date).format("DD-MM-YYYY, h:mm:ss a");
  };

  const installmentType = ({ installment }) => {
    switch (installment) {
      case "part":
        return "First Installment";
      case "second_installment":
        return "Second Installment";
      case "full":
        return "Full Payment";
      default:
        break;
    }
  };

  const actions = (_data) => {
    let { payment_status, paystack_ref } = _data;
    return (
      <div className="d-flex">
        {(payment_status?.toLowerCase() === "success" ||
          payment_status?.toLowerCase() === "paid" ||
          payment_status?.toLowerCase() === "approved") && (
          <button
            className="btn btn-success mr-1"
            onClick={() =>
              navigate("/invoice/school-fee", { state: { _data } })
            }
          >
            Print
          </button>
        )}
        {paystack_ref &&
        (payment_status?.toLowerCase() === "success" ||
          payment_status?.toLowerCase() === "paid" ||
          payment_status?.toLowerCase() === "approved") ? (
          ""
        ) : (
          <button
            className="btn btn-warning mr-1"
            onClick={() => paymentRequery(paystack_ref)}
          >
            Requery
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    getAllPayment();
  }, []);

  return (
    <div class="page-wrapper">
      {details && (
        <div class="">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active">
                        Payments Management
                      </li>
                    </ol>
                  </div>
                  <h4 class="page-title">Payments Management</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 ">
                        <div class="card">
                          <div class="card-body">
                            <div>
                              <DataTable
                                value={payment}
                                paginator
                                size="small"
                                showGridlines
                                stripedRows
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                tableStyle={{ minWidth: "50rem" }}
                              >
                                <Column
                                  field="paystack_ref"
                                  header="Payment ID"
                                ></Column>
                                <Column
                                  field="generated_date"
                                  header="Payment Date"
                                  body={paymentFormat}
                                ></Column>
                                <Column
                                  header="Category"
                                  field="installment"
                                  body={installmentType}
                                ></Column>
                                <Column
                                  header="Amount"
                                  body={priceBodyTemplate}
                                ></Column>
                                <Column
                                  field="payment_session"
                                  header="Session"
                                ></Column>
                                <Column
                                  field="payment_level"
                                  header="Payment Level"
                                ></Column>
                                <Column
                                  field="payment_status"
                                  header="Status"
                                ></Column>
                                <Column
                                  header="Actions"
                                  body={actions}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllPayments;
