import React from 'react'

const InputField = ({label, value, name}) => {
  return (
    <div className="w-full">
    <label className="text-sm font-medium text-gray-600">
      {label}
    </label>
    <input
      type="text"
      placeholder={value}
      className="form-control text-black placeholder:text-black font-semibold capitalize"
      disabled
    />
  </div>
  )
}

export default InputField