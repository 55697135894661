import axios from "axios";
import api from "../../api/api";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { Country, State, City } from "country-state-city";
import Footer from "../../components/Footer";
import useDetails from "../../hooks/useDetails";
import { getCurrentUser } from "../../utils/authService";
import avatar from "../../assets/images/user.png";
import $ from "jquery";
import "dropify/dist/css/dropify.min.css";
import "dropify";
import { LuArrowLeft, LuArrowUpRight } from "react-icons/lu";
import moment from "moment";

function UpdateBioData() {
  const [details, setDetails] = useState();
  const [file, setFile] = useState();
  const [bio, setBio] = useState();
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(data?.country_origin);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState(data?.state_origin);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(data?.lga_origin);
  const [title, setTitle] = useState(data?.title);
  const [surname, setSurname] = useState(data?.surname);
  const [othername, setOthername] = useState(data?.othername);
  const [username, setUsername] = useState(data?.username);
  const [passport, setPassport] = useState(data?.photo);
  const [dob, setDob] = useState(data?.dob);
  const [mob, setMob] = useState(data?.mob);
  const [yob, setYob] = useState(data?.yob);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [religion, setReligion] = useState(data?.religion);
  const [maritalStatus, setMaritalStatus] = useState(data?.marital_status);

  const [nokTitle, setNokTitle] = useState("");
  const [nokEmail, setNokEmail] = useState("");
  const [nokSurname, setNokSurname] = useState("");
  const [nokOthername, setNokOthername] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nokAddress, setNokAddress] = useState("");
  const [nokPhone, setNokPhone] = useState("");

  const [nokCountry, setNokCountry] = useState("");
  const [nokState, setNokState] = useState("");
  const [nokCity, setNokCity] = useState("");

  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const navigate = useNavigate();

  const user = getCurrentUser();

  const location = useLocation();

  const { staging: stage, data: detailsData } = useDetails();

  const inputRef = useRef(null);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const updateBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/update/${user.application_id}`,
        {
          email: email,
          phone: phone,
          surname,
          othername,
          username,
          title,
          dob,
          mob,
          yob,
          country_origin: selectedCountry,
          state_origin: selectedState,
          lga_origin: selectedCity,
          gender,
          religion,
          marital_status: maritalStatus,
        }
      );
      console.log(res);
      toast.success("BioData updated", successProp);
      // getDetails();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const updateNokBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/nok/update/${user.application_id}/`,
        {
          email: nokEmail,
          phone: nokPhone,
          surname: nokSurname,
          othername: nokOthername,
          title: nokTitle,
          country: nokCountry,
          state: nokState,
          city: nokCity,
          address: nokAddress,
        }
      );
      console.log(res);
      toast.success("Next of kin biodata updated", successProp);
      // getDetails();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const handleSelectGender = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const country = [...new Set(data.map((item) => item.country))].sort();

  const handleCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setSelectedCountry(coun);
      let states = NaijaStates.states();
      setState(states);
    } else {
      setSelectedCountry(coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleState = (e) => {
    let st = e.target.value;

    if (selectedCountry === "Nigeria") {
      setSelectedState(st);
      let strValue = st.toString();
      const dCities = NaijaStates.lgas(strValue);
      setCities(dCities.lgas);
    } else {
      setSelectedState(st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      setCities(cities);
    }
  };

  const handleCity = (e) => {
    let ct = e.target.value;
    setSelectedCity(ct);
  };

  // Nok of kin country, state and city handler

  const handleNokCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setNokCountry(coun);
      let states = NaijaStates.states();
      setState(states);
    } else {
      setNokCountry(coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleNokState = (e) => {
    let st = e.target.value;

    if (nokCountry === "Nigeria") {
      setNokState(st);
      let strValue = st.toString();
      const dCities = NaijaStates.lgas(strValue);
      setCities(dCities.lgas);
    } else {
      setNokState(st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      setCities(cities);
    }
  };

  const handleNokCity = (e) => {
    let ct = e.target.value;
    setNokCity(ct);
  };

  // const getDetails = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await GetApplicantDetails(user.application_id);
  //     setPassport(data.photo);
  //     setTitle(data.title);
  //     setSurname(data.surname);
  //     setOthername(data.othername);
  //     setEmail(data.email);
  //     setPhone(data.phone);
  //     setUsername(data.username);
  //     setGender(data.gender);
  //     setSelectedCity(data.lga_origin);
  //     setSelectedState(data.state_origin);
  //     setSelectedCountry(data.country_origin);
  //     setDob(data.dob);
  //     setMob(data.mob);
  //     setYob(data.yob);
  //     setReligion(data.religion)
  //     setMaritalStatus(data.marital_status)
  //     setLoading(false);
  //     setDetails(data);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

  const validateFileType = (file) => {
    return allowedFileTypes.includes(file.type);
  };

  const handleFileInput = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handlePassportChange(file);
    }
  };

  const handlePassportChange = (file) => {
    if (!validateFileType(file)) {
      alert("Invalid file type. Please upload a valid file type");
      setPreviewUrl(null);
      return;
    }

    if (file.size / 1024 / 1024 > 5) {
      alert("Please upload an image lower than 5MB");
      setPreviewUrl(null);
    }

    setFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setPreviewUrl(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleBiodataUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("photo", file ? file : "");
    formData.append(
      "yob",
      dateOfBirth ? moment(dateOfBirth)?.format("YYYY") : ""
    );
    formData.append(
      "mob",
      dateOfBirth ? moment(dateOfBirth)?.format("MM") : ""
    );
    formData.append("dob", dateOfBirth ? moment(dateOfBirth)?.format("D") : "");
    formData.append("religion", religion ? religion : "");
    formData.append("phone", phone ? phone : "");
    formData.append("marital_status", maritalStatus ? maritalStatus : "");
    formData.append("email", email ? email : "");
    formData.append("country_origin", selectedCountry ? selectedCountry : "");
    formData.append("state_origin", selectedState ? selectedState : "");
    formData.append("lga_origin", selectedCity ? selectedCity : "");

    try {
      setLoading(true);
      toast.loading("Updating Biodata...");
      const { data } = await api.patch(
        `/student/biodata-update/${user}`,
        formData
      );
      console.log("Updated biodata", data);
      toast.success("Update successful");
      setPreviewUrl(null);
      setLoading(false);
      window.location.reload();
    } catch (error) {}
  };

  const dataFormater = (data) => {
    let day, month, year = data?.yob;

    if (day === null || month === null || year === null) return;

    //Format day
    if (data?.dob.length === 1) {
      day = `0${data?.dob}`;
    }else {
      day = data?.dob
    }

    //Format month
    if (data?.mob.length === 1) {
      month = `0${data?.mob}`;
    }else {
      month = data?.mob
    }

    setDateOfBirth(`${year}-${month}-${day}`)


  };

  useEffect(() => {
    setPhone(detailsData?.phone);
    setEmail(detailsData?.email);
    dataFormater(detailsData)

  }, [detailsData]);

  useEffect(() => {
    $(inputRef.current).dropify();
  });

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }
  if (stage === "completed" || stage === "rejected" || stage === "approved") {
    return (
      <Navigate
        to={{
          pathname: `/apply`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div className="">
      {detailsData && (
        <div className="space-y-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <LuArrowLeft
                className="size-6 text-brand-black cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <div className="space-y-1">
                <h1 className="text-2xl font-bold text-brand-darkGray">
                  Profile
                </h1>
                <p className="text-sm text-gray-400">
                  View and edit profile information
                </p>
              </div>
            </div>
            <button
              className="bg-[#242D6D] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#242D6D]/95 hover:scale-105 transition-all ease-in-out"
              onClick={() => navigate("/result")}
            >
              <p className="text-sm font-medium text-white">View Results</p>
              <div className="size-9 bg-white rounded-lg flex justify-center items-center cursor-pointer group">
                <LuArrowUpRight className="size-[18px] text-[#242D6D] group-hover:scale-125 transition-all ease-in-out" />
              </div>
            </button>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="tab-content detail-list" id="pills-tabContent">
                <div className="tab-pane fade show active" id="general_detail">
                  <div className="row">
                    <div className="col-lg-12  mx-auto">
                      <div className="card">
                        <div className="card-body">
                          <form
                            onSubmit={handleBiodataUpdate}
                            className="card-box"
                          >
                            <div
                              className="w-full bg-[#FCFCFC] border border-[#EAECF0] rounded-lg flex flex-col md:flex-row md:items-center gap-7 p-5 cursor-pointer hover:bg-black/5"
                              onClick={() => {
                                const fileField =
                                  document.querySelector(".file-field");
                                if (fileField) {
                                  fileField.click();
                                }
                              }}
                            >
                              {previewUrl ? (
                                <img
                                  alt="passport"
                                  className="size-[90px] rounded-full object-cover"
                                  src={previewUrl}
                                />
                              ) : (
                                <img
                                  alt="passport"
                                  className="size-[90px] rounded-full object-cover"
                                  src={
                                    detailsData?.photo
                                      ? detailsData?.photo
                                      : avatar
                                  }
                                />
                              )}
                              <div className="space-y-2">
                                <p className="text-xl font-semibold text-brand-darkGray">
                                  Student Photo
                                </p>
                                <p className="text-sm text-gray-400">
                                  Click here to edit your passport photo.
                                </p>
                              </div>
                            </div>

                            <input
                              aria-label="File input field"
                              className="file-field sr-only"
                              type="file"
                              onChange={handleFileInput}
                              accept="image/png, image/jpg, image/jpeg"
                            />

                            <div className="mt-4 space-y-5">
                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Title
                                  </label>
                                  <select
                                    type="text"
                                    defaultValue={detailsData?.title}
                                    className="form-control text-black font-semibold"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    disabled
                                  >
                                    <option>
                                      {title ? title : "--Title--"}
                                    </option>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Miss</option>
                                  </select>
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.surname}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.othername}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Matric Number
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.matric_no}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Application/Registration Number
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.application_no}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Jamb Number
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.jamb_no}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control text-black font-semibold"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control text-black font-semibold"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Date of Birth
                                  </label>
                                  <input
                                    className="form-control text-black font-semibold capitalize"
                                    value={dateOfBirth}
                                    onChange={(e) =>
                                      setDateOfBirth(e.target.value)
                                    }
                                    type="date"
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Gender
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold capitalize"
                                    value={gender}
                                    onChange={handleSelectGender}
                                    disabled
                                  >
                                    <option>
                                      {detailsData?.gender
                                        ? detailsData?.gender
                                        : "--Gender--"}
                                    </option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Marital Status
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold capitalize"
                                    value={maritalStatus}
                                    onChange={(e) =>
                                      setMaritalStatus(e.target.value)
                                    }
                                  >
                                    <option>
                                      {detailsData?.marital_status
                                        ? detailsData?.marital_status
                                        : "--Select--"}
                                    </option>
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Seperated</option>
                                  </select>
                                </div>
                              </div>

                              <p className="mt-5 text-sm font-medium text-gray-600">
                                Academic Details
                              </p>

                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Department
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.department}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Level of Study
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={`${detailsData?.level} Level`}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Academic Session
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.current_session}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    School
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.faculty}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Entry Mode
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.entry_mode}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Entry Session
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.entry_session}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Start
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.start}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    End
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={detailsData?.end}
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col lg:flex-row gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Religion
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold"
                                    value={religion}
                                    onChange={(e) =>
                                      setReligion(e.target.value)
                                    }
                                  >
                                    <option>
                                      {detailsData.religion
                                        ? detailsData.religion
                                        : "--Select--"}
                                    </option>
                                    <option>Christianity</option>
                                    <option>Islam</option>
                                    <option>Others</option>
                                  </select>
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Fees
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control text-black font-semibold"
                                    disabled
                                  />
                                </div>
                              </div>

                              <p className="mt-5 text-sm font-medium text-gray-600">
                                Background
                              </p>

                              <div className="flex flex-col gap-5">
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    Country
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold capitalize"
                                    onChange={handleCountry}
                                  >
                                    <option>
                                      {detailsData?.country_origin
                                        ? detailsData?.country_origin
                                        : "--Country--"}
                                    </option>
                                    {country.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    State
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold capitalize"
                                    onChange={handleState}
                                  >
                                    <option>
                                      {detailsData?.state_origin
                                        ? detailsData?.state_origin
                                        : "--State--"}
                                    </option>
                                    {state.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="w-full">
                                  <label className="text-sm font-medium text-gray-600">
                                    LGA
                                  </label>
                                  <select
                                    className="form-control text-black font-semibold capitalize"
                                    onChange={handleCity}
                                  >
                                    <option>
                                      {detailsData?.lga_origin
                                        ? detailsData?.lga_origin
                                        : "--Lga--"}
                                    </option>
                                    {cities.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                <div className="col-md-4">
                                  <label>Day of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.dob}
                                    className="form-control"
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label>Month of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.mob}
                                    className="form-control"
                                    value={mob}
                                    onChange={(e) => setMob(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label>Year of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.yob}
                                    className="form-control"
                                    value={yob}
                                    onChange={(e) => setYob(e.target.value)}
                                  />
                                </div>
                              </div> */}
                              {/* <div className="form-group row">
                                <div className="col-md-4">
                                  <label>Country of Origin</label>
                                  <select
                                    className="form-control"
                                    onChange={handleCountry}
                                  >
                                    <option value="">
                                      {selectedCountry
                                        ? selectedCountry
                                        : "--Country--"}
                                    </option>
                                    {country.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-4">
                                  <label>State of Origin</label>
                                  <select
                                    className="form-control"
                                    onChange={handleState}
                                  >
                                    <option value="">
                                      {selectedState
                                        ? selectedState
                                        : "--State--"}
                                    </option>
                                    {state.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-4">
                                  <label>Local govt area</label>
                                  <select
                                    className="form-control"
                                    onChange={handleCity}
                                  >
                                    <option value="">
                                      {selectedCity ? selectedCity : "--LGA--"}
                                    </option>
                                    {cities.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div> */}
                              <div className="flex justify-end">
                                <button
                                  className="bg-[#5D71C0] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
                                  type="submit"
                                  disabled={loading ? true : false}
                                >
                                  <p className="text-sm font-medium text-white">
                                    Update Profile
                                  </p>
                                  <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
                                    <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateBioData;
