import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  Navigate,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { RiHomeLine } from "react-icons/ri";
import { FaNairaSign } from "react-icons/fa6";
import { FiBookOpen, FiFileText, FiInfo, FiMapPin } from "react-icons/fi";
import { LuBook, LuLogOut } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { HiAcademicCap } from "react-icons/hi2";
import { InstitutionName, SchoolLogo } from "../utils";
import { removeUser } from "../utils/authService";

const navigation = [
  { name: "Dashboard", href: "", icon: RiHomeLine, current: false },
  { name: "Payments", href: "payments", icon: FaNairaSign, current: false },
  { name: "Biodata", href: "biodata", icon: FiFileText, current: false },
  {
    name: "Accommodation",
    href: "accommodation",
    icon: FiMapPin,
    current: false,
  },
  {
    name: "Course Registration",
    href: "course-reg",
    icon: LuBook,
    current: false,
  },
  { name: "Library", href: "library", icon: FiBookOpen, current: false },
  { name: "Complaint", href: "complaint", icon: FiInfo, current: false },
  {
    name: "Result",
    href: "result",
    icon: HiAcademicCap,
    current: false,
  },
  // {
  //   name: "Settings",
  //   href: "settings",
  //   icon: IoSettingsOutline,
  //   current: false,
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const normalLink =
  "text-gray-400 hover:text-brand-blue hover:bg-brand-blue/10 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold";
const activeLink =
  "bg-brand-blue/10 text-brand-blue group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold";

export default function NewLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("student-token");

  if (token === null) {
    return (
      <Navigate
        to={{
          pathname: `/login`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  const logOut = () => {
    removeUser();
    navigate("/login");
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="mt-8 flex h-16 shrink-0 items-center">
                      <img
                        className="h-14 w-auto"
                        src={SchoolLogo}
                        alt="school_logo"
                      />
                      <h2 className="text-lg text-[#141446] font-bold">
                        Student Portal
                      </h2>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-4">
                            {navigation.map((item) => (
                              <li
                                key={item.name}
                                onClick={() => setSidebarOpen(false)}
                              >
                                <NavLink
                                  to={item.href}
                                  className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                  }
                                  end
                                >
                                  <item.icon
                                    className={classNames(
                                      "group-hover:text-brand-blue h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <button
                            href="#"
                            className="group mx-2 w-full rounded-md p-2 text-sm font-semibold leading-6 text-[#5D1C1C] bg-[#FFFAFA] flex justify-between"
                            onClick={logOut}
                          >
                            Logout
                            <LuLogOut
                              className="h-6 w-6 shrink-0 text-[#D31010]"
                              aria-hidden="true"
                            />
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="mt-8 flex h-16 shrink-0 items-center">
              <img className="h-14 w-auto" src={SchoolLogo} alt="school_logo" />
              <h2 className="text-lg text-[#141446] font-bold">
                Student Portal
              </h2>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-4">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                          end
                        >
                          <item.icon
                            className={classNames(
                              "group-hover:text-brand-blue h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <button
                    href="#"
                    className="group mx-2 w-full rounded-md p-2 text-sm font-semibold leading-6 text-[#5D1C1C] bg-[#FFFAFA] flex justify-between"
                    onClick={logOut}
                  >
                    Logout
                    <LuLogOut
                      className="h-6 w-6 shrink-0 text-[#D31010]"
                      aria-hidden="true"
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}

            <div className="flex flex-1 ">
              <h1 className="text-sm md:text-lg lg:text-xl font-bold">
                {InstitutionName}
              </h1>
            </div>
          </div>

          <main className="py-10 bg-gray-100">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
