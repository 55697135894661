import React from "react";
import { classNames } from "../utils";
import { FiLoader } from "react-icons/fi";

const Button = ({
  text,
  type = "button",
  icon,
  className = "",
  onClick,
  loading = false,
  disabled = false,
  dataModal = "",
  dataToggle = ""
}) => {
  let Icon = icon;
  return (
    <button
      className={classNames(className, disabled && "disabled:cursor-not-allowed opacity-30", "cursor-pointer bg-[#5D71C0] text-white py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:opacity-85 hover:scale-105 transition-all ease-in-out")}
      data-toggle={dataModal}
      data-target={dataToggle}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
    >
        {loading ? (
        <FiLoader className="size-5 animate-spin mx-auto" />)
    :
    <>
      {text}
      {icon &&
      <div className="size-9 bg-[#151D3B1A] rounded-lg flex justify-center items-center cursor-pointer group">
        <Icon className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
      </div>
      }
    </>}
    </button>
  );
};

export default Button;
