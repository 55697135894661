import { useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../components/Footer";
import api from "../../api/api";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import { GetStudentDetails } from "../../api/student";
import usePayments from "../../hooks/usePayments";
import StudentInfo from "../../components/StudentInfo";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { LuArrowLeft, LuArrowUpRight } from "react-icons/lu";
import BiodataSection from "../../components/BiodataInfo";
import Button from "../../components/Button";

const CourseRegistration = () => {
  const { data } = useDetails();
  const paymentComplete = data?.payment_complete;
  console.log("pauyment", paymentComplete);
  const { payment } = usePayments();
  const [level, setLevel] = useState();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [mappedCourses, setMappedCourses] = useState([]);
  const [registeredCourses, setRegisteredCourses] = useState([]);
  const [registeredShow, setRegisteredShow] = useState(false);
  const [validation, setValidation] = useState();
  const [rowClick, setRowClick] = useState(true);
  const [totalSelectedUnits, setTotalSelectedUnits] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState();
  const [faculty, setFaculty] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedFilteredCourses, setSelectedFilteredCourses] = useState();
  const [query, setQuery] = useState("");
  const [session, setSession] = useState("");
  const [semesterDetails, setSemesterDetails] = useState([]);
  const [availableSessions, setAvailableSessions] = useState([]);
  const [
    currentSessionModificationStatus,
    setCurrentSessionModificationStatus,
  ] = useState(null);
  const [program, setProgram] = useState("");

  const theCourses = mappedCourses?.map((course) => course.course_id);

  const [grade, setGrade] = useState("");
  const [semester, setSemester] = useState();

  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const user = getCurrentUser();

  const token = user?.access;

  const totalUnits = selectedCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  console.log("totalUnits", totalUnits);

  const getDetails = async () => {
    setLoading(true);
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level);
      setProgram(data?.course_of_study);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //   const fetchPaymentStatus = async () => {
  //     try {
  //       const { data } = await GetPaymentStatus(user.application_id);
  //       console.log(data, "statuss");
  //       setAdmissionFee(data.application_fee);
  //       setResultFee(data.result_checking_fee);
  //     } catch (error) {}
  //   };

  // const getCourses = async () => {
  //   try {
  //     const { data } = await api.get(`/courses/all/`);
  //     console.log("Courses list", data.data);
  //     setCourses(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getMappedCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/mapped/read?mapped_level=${level}&semester_int=${semester}&mapped_program=${program}`
      );
      console.log("Mapped list", data.data);
      // setMappedCourses(data.data);
      setCourses(data.data.map((course) => course.course_id));
    } catch (error) {
      console.log(error);
    }
  };

  const getFilteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/mapped/read?course_id__code=${query}`
      );
      console.log("Queried list", data.data);
      // setFilteredCourses(data.data)
      setFilteredCourses(data.data.map((course) => course.course_id));
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await api.get(`/department/all?page_size=${70}`);
      console.log("department list", data?.results);
      setDepartments(data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/student-view?registration_level=${level}&session=${session}`
      );
      setRegisteredCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  const MAX_UNITS = 80;

  const handleSelectionChange = (e) => {
    // Calculate the total units of the selected courses
    const newSelectedCourses = e.value;
    console.log("newSelec-->", newSelectedCourses);
    let totalUnits = 0;
    newSelectedCourses.forEach((theCourses) => {
      totalUnits += parseInt(theCourses.unit); // Assuming quantity represents units
    });

    // Check if the total units exceed the allowed maximum
    if (totalUnits <= MAX_UNITS) {
      setSelectedCourses(newSelectedCourses);
      setTotalSelectedUnits(totalUnits);
    } else {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units`);
    }
    // If the total units exceed the limit, don't update the selectedProducts state
  };

  function isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }

  const handleCourseSelection = (event) => {
    if (isObject(event)) {
      const course = event;
      const newTotalUnits = totalUnits + parseInt(course.unit);

      // Check if adding the new course will exceed the maximum units
      if (newTotalUnits > MAX_UNITS) {
        alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
        return;
      }

      // Check if course is already in selectedCourses array
      if (
        selectedCourses.some(
          (selectedCourse) => selectedCourse.id === course.id
        )
      ) {
        alert("Course already selected!");
        return;
      }

      setSelectedCourses((prevSelectedCourses) => [
        ...prevSelectedCourses,
        course,
      ]);
      setQuery("");
    } else {
      const course = JSON.parse(event.target.value);
      const newTotalUnits = totalUnits + parseInt(course.unit);

      // Check if adding the new course will exceed the maximum units
      if (newTotalUnits > MAX_UNITS) {
        alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
        return;
      }

      // Check if course is already in selectedCourses array
      if (
        selectedCourses.some(
          (selectedCourse) => selectedCourse.id === course.id
        )
      ) {
        alert("Course already selected!");
        return;
      }

      setSelectedCourses((prevSelectedCourses) => [
        ...prevSelectedCourses,
        course,
      ]);
    }
  };

  const handleCourseDelete1 = (id) => {
    let newList = selectedCourses.filter((item) => item.id !== id);
    setSelectedCourses(newList);
  };

  const validated = async () => {
    try {
      const { data } = await api.get(`/courses/validated/first/${level}`);
      setValidation(data?.data[0]);
      console.log("sasass", data?.data[0]);
    } catch (error) {}
  };

  const deleteRegisteredCourses = async (id) => {
    try {
      const { data } = await api.delete(
        `/courses/remove-registered-course/${id}`
      );
      getRegisteredCourses();
      toast.success("Course deleted");
    } catch (error) {}
  };

  const registerCourses = async (e) => {
    e.preventDefault();
    if (!session) return toast.info("Select a session");
    if (selectedCourses.length === 0) return;
    setLoading(true);
    try {
      const { data } = await api.post("/courses/register", {
        courses: selectedCourses,
        registration_level: level,
        session,
      });
      toast.success("Course registration successful", successProp);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong!")
    }
  };

  const getAvailableSession = async () => {
    try {
      const { data } = await api.get(`/setup/sessions/read`);
      setSemesterDetails(data.data);
    } catch (error) {}
  };

  const handleFirstSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your First Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "first",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  const handleSecondSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your Second Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "second",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  useEffect(() => {
    getDetails();
    getDepartments();
    getAvailableSession()
    // fetchPaymentStatus();
  }, []);

  useEffect(() => {
    getRegisteredCourses();
  }, [session, level]);

  useEffect(() => {
    validated();
    getMappedCourses();
    // getCourses();
  }, [level, semester, program, session]);

  useEffect(() => {
    getFilteredCourses();
  }, [query]);

  useEffect(() => {
    if (!session) return;
    let data = semesterDetails.find((item) => item.name === session);
    setCurrentSessionModificationStatus(data.course_modifiable);
  }, [session, semesterDetails]);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  //New design and implementation for course reg

  const [coursesSelected, setCoursesSelected] = useState([]);

  // Prioritize courses: carryover > compulsory > elective
  const sortedCourses = [...courseSample].sort((a, b) => {
    const priority = { carryover: 1, compulsory: 2, elective: 3 };
    return priority[a.type] - priority[b.type];
  });

  // Toggle course selection
  const toggleSelection = (course) => {
    setCoursesSelected((prev) =>
      prev.some((c) => c.id === course.id)
        ? prev.filter((c) => c.id !== course.id)
        : [...prev, course]
    );
  };

  return (
    <div class="">
      {data && courses && (
        <div class="">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <LuArrowLeft
                className="size-6 text-brand-black cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <div className="space-y-1">
                <h1 className="text-2xl font-bold text-brand-darkGray">
                  Course registration
                </h1>
                <p className="text-sm text-gray-400">
                  Register your courses for this semester
                </p>
              </div>
            </div>
            <button
              className="bg-[#5D71C0] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out disabled:cursor-not-allowed disabled:opacity-55"
              onClick={() => navigate("/course-reg/history")}
            >
              <p className="text-sm font-medium text-white">
                Course history
              </p>
              <div className="size-9 bg-[#151D3B1A] rounded-lg flex justify-center items-center cursor-pointer group">
                <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
              </div>
            </button>
          </div>

          <div className="mt-6 card-body rounded-xl bg-white space-y-10">
            <BiodataSection data={data} />

            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-12 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <div class="container">
                              <>
                                <div class="form-group mb-3">
                                  <button
                                    class="bg-[#5D71C0] text-white py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:opacity-85 hover:scale-105 transition-all ease-in-out"
                                    disabled={loading}
                                    onClick={() => {
                                      if (!session)
                                        return toast.info("Select a session");
                                      setRegisteredShow(true);
                                    }}
                                  >
                                    View Registered Courses
                                  </button>
                                </div>
                                <hr />

                                <hr />
                                {registeredShow ? (
                                  <>
                                    {registeredCourses.length ? (
                                      <>
                                        <h2 className="text-sm font-medium my-3">
                                          Registered Courses
                                        </h2>
                                        <table className="w-full">
                                          <thead className="dspg-dark">
                                            <th>Subject</th>
                                            <th>Grade</th>
                                            <th>Unit</th>
                                            <th>Semester</th>
                                            <th>Status</th>
                                            <th></th>
                                          </thead>
                                          {registeredCourses?.map((list) => (
                                            <tbody>
                                              <td>{list.code}</td>
                                              <td>{list.title}</td>
                                              <td>{list.unit}</td>
                                              <td>{list.semester}</td>
                                              <td>
                                                {list.is_approved
                                                  ? "Approved"
                                                  : "Not Approved"}
                                              </td>
                                              {validation?.is_validated ? (
                                                <td></td>
                                              ) : (
                                                <td
                                                  onClick={() =>
                                                    deleteRegisteredCourses(
                                                      list.id
                                                    )
                                                  }
                                                >
                                                  <Trash2 className="waves-effect align-self-center icon-sm" />
                                                </td>
                                              )}
                                            </tbody>
                                          ))}
                                          <tfoot>
                                            <tr>
                                              <td className="h5">
                                                Total Units
                                              </td>
                                              <td></td>
                                              <td className="h5">
                                                {totalRegisteredUnits}
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td colSpan={6} className="h5">
                                                Course Advisor: {}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={6}>
                                                {/* {validation?.is_validated && ( */}
                                                {currentSessionModificationStatus && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="dspg-info py-2 px-3 rounded-lg mr-2"
                                                      onClick={
                                                        handleFirstSemesterReset
                                                      }
                                                    >
                                                      Reset First Semester
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="dspg-dark py-2 px-3 rounded-lg mr-2"
                                                      onClick={
                                                        handleSecondSemesterReset
                                                      }
                                                    >
                                                      Reset Second Semester
                                                    </button>
                                                  </>
                                                )}
                                                <button
                                                  type="submit"
                                                  className="dspg-danger py-2 px-3 rounded-lg"
                                                  onClick={() =>
                                                    navigate(
                                                      "/course-reg/print",
                                                      { state: session }
                                                    )
                                                  }
                                                >
                                                  Printout Course
                                                </button>
                                                {/* )} */}
                                              </td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </>
                                    ) : (
                                      <div className="alert dspg-info">
                                        No Courses Registered Yet
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                              <div className="w-full border rounded-xl p-3 space-y-3">
                                <CourseTableHeader
                                  data={data}
                                  semester={semester}
                                  setSemester={setSemester}
                                  level={level}
                                  setLevel={level}
                                  session={session}
                                  setSession={setSession}
                                />
                                <div className="flex flex-col md:flex-row gap-y-3 items-center justify-between">
                                  <div className="flex gap-6">
                                    <p className="text-base font-bold text-brand-black">
                                      {data?.level} Level
                                    </p>
                                    <p className="text-base font-bold text-brand-darkGray">
                                      {semester === "1"
                                        ? "1st"
                                        : semester === "2"
                                        ? "2nd"
                                        : ""}{" "}
                                      Semester
                                    </p>
                                    <p className="text-base font-bold text-brand-blue">
                                      Maximum: {MAX_UNITS} units
                                    </p>
                                    <p className="text-base font-bold text-brand-black">
                                      Selected Units: {totalUnits}
                                    </p>
                                  </div>

                                  <div className="flex gap-4">
                                    <Button
                                      text="Add more courses"
                                      icon={LuArrowUpRight}
                                      className=""
                                      dataModal="modal"
                                      dataToggle="#exampleModalCenter"
                                      disabled={!semester}
                                    />
                                    <Button
                                      text="Register Courses"
                                      onClick={registerCourses}
                                      className="bg-green-100 !text-green-900"
                                      loading={loading}
                                      disabled={
                                        selectedCourses.length === 0 || loading
                                      }
                                    />
                                  </div>
                                </div>
                                {semester && courses.length > 0 && (
                                  <DataTable
                                    value={courses}
                                    selectionMode={rowClick ? null : "checkbox"}
                                    selection={selectedCourses}
                                    onSelectionChange={handleSelectionChange}
                                    dataKey="id"
                                    tableStyle={{ minWidth: "50rem" }}
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                  >
                                    <Column
                                      selectionMode="multiple"
                                      headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                      field="code"
                                      header="Course Code"
                                    ></Column>
                                    <Column
                                      field="title"
                                      header="Course Title"
                                    ></Column>
                                    <Column
                                      field="unit"
                                      header="Course Unit"
                                    ></Column>
                                    <Column
                                      field="semester"
                                      header="Semester"
                                    ></Column>
                                  </DataTable>
                                )}
                              </div>

                              {selectedCourses?.length ? (
                                <div className="bg-light w-full">
                                  <table className="mt-3 col-lg-12">
                                    <thead className="dspg-dark">
                                      <th>Code</th>
                                      <th>Course</th>
                                      <th>Unit</th>
                                      <th>Semester</th>
                                    </thead>
                                    {selectedCourses?.map((list) => (
                                      <tbody>
                                        <td>{list.code}</td>
                                        <td>{list.title}</td>
                                        <td>{list.unit}</td>
                                        <td>{list.semester}</td>
                                      </tbody>
                                    ))}
                                    <tfoot>
                                      <tr>
                                        <td className="h5">Total Units</td>
                                        <td></td>
                                        <td className="h5">{totalUnits}</td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Search Courses
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/*body */}
              <div className="form-group row">
                <div className="col-md-6 position-relative">
                  <label>Search Course Code</label>
                  <input
                    className="form-control"
                    placeholder="Search..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <div
                    className="col-md-12 position-absolute bottom-50 start-50 bg-white"
                    style={{ zIndex: "100", cursor: "pointer" }}
                  >
                    {query.length >= 2 && (
                      <>
                        {filteredCourses?.map((filtered, index) => (
                          <h6
                            key={index}
                            onClick={() => handleCourseSelection(filtered)}
                            className="text-sm p-1 hover:bg-gray-100"
                          >
                            {filtered.code} - {filtered.title}
                          </h6>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {selectedCourses?.length ? (
                <div className="bg-light">
                  <p className="breadcrumb-item">Selected Courses</p>
                  <table className="col-lg-12">
                    <thead className="dspg-dark">
                      <th>Code</th>
                      <th>Title</th>
                      <th>Unit</th>
                      <th>Semester</th>
                    </thead>
                    {selectedCourses?.map((list) => (
                      <tbody>
                        <td>{list.code}</td>
                        <td>{list.title}</td>
                        <td>{list.unit}</td>
                        <td>{list.semester}</td>
                        <td onClick={() => handleCourseDelete1(list.id)}>
                          <Trash2 className="waves-effect align-self-center icon-dual-pink icon-sm" />
                        </td>
                      </tbody>
                    ))}
                    <tfoot>
                      <tr>
                        <td className="h5">Total Units</td>
                        <td></td>
                        <td className="h5">{totalUnits}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : (
                ""
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;

const CourseTableHeader = ({
  data,
  semester,
  setSemester,
  level,
  setLevel,
  session,
  setSession,
}) => {
  const [availableSessions, setAvailableSessions] = useState([]);

  useEffect(() => {
    const getAvailableSession = async () => {
      try {
        const { data } = await api.get(`/setup/sessions/read`);
        setAvailableSessions(data.data.map((item) => item.name).reverse());
        console.log("available sessions", data);
      } catch (error) {}
    };

    getAvailableSession();
  }, []);
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="w-full">
          <label className="text-sm font-medium text-gray-600">
            Select session
          </label>
          <select
            type="text"
            defaultValue={data?.entry_session}
            className="form-control text-black font-semibold"
            value={session}
            onChange={(e) => setSession(e.target.value)}
          >
            <option></option>
            {availableSessions?.map((session, index) => (
              <option key={index}>{session}</option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label className="text-sm font-medium text-gray-600">
            Select Level
          </label>
          <select
            type="text"
            defaultValue={data?.level}
            className="form-control text-black font-semibold"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          >
            <option>100</option>
            <option>200</option>
            <option>300</option>
            <option>400</option>
            <option>500</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-5 mb-3">
        <div className="lg:w-1/2">
          <label className="text-sm font-medium text-gray-600">
            Select semester
          </label>
          <select
            type="text"
            defaultValue={data?.level}
            className="form-control text-black font-semibold"
            value={semester}
            onChange={(e) => setSemester(e.target.value)}
          >
            <option value="">-- Select semester --</option>
            <option value="1">First</option>
            <option value="2">Second</option>
          </select>
        </div>
      </div>
    </>
  );
};

const courseSample = [
  {
    id: 1,
    title: "Business Ethics",
    code: "BUS401",
    unit: 3,
    type: "compulsory",
  },
  {
    id: 2,
    title: "Strategic Management",
    code: "BUS402",
    unit: 3,
    type: "compulsory",
  },
  {
    id: 3,
    title: "Corporate Finance",
    code: "BUS403",
    unit: 3,
    type: "carryover",
  },
  {
    id: 4,
    title: "International Business",
    code: "BUS404",
    unit: 3,
    type: "elective",
  },
  {
    id: 5,
    title: "Marketing Strategies",
    code: "BUS405",
    unit: 3,
    type: "elective",
  },
];
