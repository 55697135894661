import { useEffect, useState, useCallback } from "react";
import api from "../api/api";

const useActiveSession = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchActiveSession = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/setup/sessions/read");
      const activeSession = data?.data.find((sess) => sess.status === "active");
      setSession(activeSession || null); // Ensure null if no active session
    } catch (error) {
      console.error("Error fetching active session:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchActiveSession();
  }, [fetchActiveSession]);

  return { session, loading }; // Expose loading for better hook usability
};

export default useActiveSession;
