import React from "react";
import { LuArrowLeft } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const CarryoverCourse = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <LuArrowLeft
            className="size-6 text-brand-black cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="space-y-1">
            <h1 className="text-2xl font-bold text-brand-darkGray">
              Carry over courses
            </h1>
            <p className="text-sm text-gray-400">
              Register your courses for this semester
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 bg-white rounded-xl card-body border border-[#F1F1F1]">
        <div className="space-y-4">
          <h5 className="text-lg font-semibold text-brand-darkGray">
            &#x2022; Carry over courses
          </h5>
          <table className="mb-5 ">
            <th className=""></th>
            <th className="">S/N</th>
            <th className="">Course title</th>
            <th className="">Course code</th>
            <th className="">Unit weight</th>
            <th className="">Status</th>
            <tbody>
              {courseSample?.map((course) => (
                <tr key={course?.id}>
                  <td className="text-center">
                    <input type="checkbox" className="cursor-pointer" />
                  </td>
                  <td>{course?.id}</td>
                  <td>{course?.title}</td>
                  <td>{course?.code}</td>
                  <td>{course?.unit} units</td>
                  <td>C - Compulsory</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CarryoverCourse;

const courseSample = [
  {
    id: 1,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
  },
  {
    id: 2,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
  },
  {
    id: 3,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
  },
  {
    id: 4,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
  },
];
