import { useState } from "react";
import bgImage from "../assets/images/bg-img.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCurrentUser } from "../utils/authService";
import api from "../api/api";
import Marque from "react-fast-marquee";
import BG from "../assets/images/study-group.jpg";
import { AffiliateName, InstitutionName, SchoolLogo } from "../utils";

const PortalLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Login");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const studentLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBtnText("Loading...");
      const { data } = await api.post("/student/ajuru/login", {
        username,
        password,
      });
      console.log("Result", data.data);
      setLoading(false);
      // await asyncLocalStorage.setItem("student-token", data.data.tokens.access);
      localStorage.setItem("student-token", data.data.tokens.access);
      setCurrentUser(data.data.tokens.student);
      toast.success("Login successful", successProp);
      setBtnText("Login");
      navigate("/");
      // window.location.replace("/")
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again");
      setLoading(false);
      toast.error(error.response.data.error.message, errorProp);
    }
  };

  return (
    <div
      className="min-h-screen w-full font-inter"
      style={{
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-35"></div>
      {/* Content */}
      <div className="relative flex justify-center items-center h-full">
        <div className="w-full max-w-6xl flex flex-wrap justify-center items-center">
          {/* Left Column */}
          <div className="w-full md:w-1/2 p-5 hidden md:block">
            <div>
              <img
                className="w-[120px] h-[120px] object-cover mb-6"
                src={SchoolLogo}
                alt="Logo"
              />
              <h1 className="text-white text-4xl font-semibold leading-[43.57px]">
                {InstitutionName}
              </h1>
              {AffiliateName && (
                <>
                  <h1 className="text-white text-xl font-medium uppercase leading-[43.57px]">
                    In Affiliation with
                  </h1>
                  <h1 className="text-white text-3xl font-semibold leading-[43.57px]">
                    {AffiliateName}
                  </h1>
                </>
              )}
              <h1 className="text-white text-4xl font-semibold leading-[43.57px] pt-16">
                Building the Future...
              </h1>
              <p className="text-white mt-6">
                Refining the next generation of leaders and industry
                professionals through a robust and inclusive academic
                curriculum.
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full md:w-1/2 p-4">
            {/* On Small Screens: Show Logo and Text Above Form */}
            <div className="block md:hidden text-center mb-6">
              <img
                className="w-[60px] h-[60px] mx-auto"
                src={SchoolLogo}
                alt="Logo"
              />
              <h1 className="text-white text-2xl font-bold mt-4">
                {InstitutionName}
              </h1>
              {AffiliateName && (
                <>
                  <h1 className="text-white text-lg font-medium mt-2">
                    In Affiliation with
                  </h1>
                  <h1 className="text-white text-2xl font-bold mt-2">
                    {AffiliateName}
                  </h1>
                </>
              )}
            </div>

            {/* Form */}
            <div className="bg-[hsla(0,17%,95%,0.95)] w-full max-w-md p-6 rounded-xl shadow-xl mx-auto">
              {/* Header */}
              <h5 className="text-gray-600 text-sm font-poppins">
                Happy to have you here
              </h5>
              <h1 className="text-black text-2xl leading-[44px] font-medium mb-6 font-poppins">
                Sign in to your account
              </h1>

              <form className="space-y-4" onSubmit={studentLogin}>
                {/* Email Input */}
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Jamb/Matric Number"
                  className="w-full bg-[rgba(255,255,255,0.2)] border border-[rgba(217,217,217,0.5)]  rounded-lg px-4 py-3 focus:outline-none focus:ring focus:ring-blue-300"
                />

                {/* Password Input */}
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="w-full bg-[rgba(255,255,255,0.2)] border border-[rgba(217,217,217,0.5)] rounded-lg px-6 py-3 focus:outline-none focus:ring focus:ring-blue-300"
                  />
                  {showPassword ? (
                    <span className="absolute right-3 top-5 text-gray-400 cursor-pointer">
                      <FaRegEye onClick={() => setShowPassword(false)} />
                    </span>
                  ) : (
                    <span className="absolute right-3 top-5 text-gray-400 cursor-pointer">
                      <FaRegEyeSlash onClick={() => setShowPassword(true)} />
                    </span>
                  )}
                </div>

                {/* Forgot Password */}
                <div className="text-left mb-10">
                  <a
                    href="/reset-password"
                    className="text-[rgba(83,80,80,1)] text-sm font-poppins font-medium  mb-8 block"
                  >
                    Forgot Password?
                  </a>
                </div>

                {/* Login Button */}
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition"
                >
                  {btnText}
                </button>
              </form>

              {/* Register Link */}
              {/* <p className="text-center text-black mt-8">
                Don't have an account?{" "}
                <a href="#" className="text-blue-500">
                  Register here
                </a>
              </p> */}

              {/* Admissions */}
              <div className="mt-6 space-y-4">
                <h3 className="text-center text-sm mt-10 font-manrope text-black">
                  Admissions
                </h3>
                <a
                  href="#"
                  target="blank"
                  type="button"
                  className="w-full font-manrope text-[rgba(34,34,34,1)] bg-[rgba(255,255,255,0.2)] border border-[rgba(34,34,34,0.1)] py-3 rounded-lg hover:bg-gray-100 transition text-center"
                >
                  Check Admission
                </a>
                <a
                  href="#"
                  target="blank"
                  type="button"
                  className="w-full font-manrope text-[rgba(34,34,34,1)] bg-[rgba(255,255,255,0.2)] border border-[rgba(34,34,34,0.1)] py-3 rounded-lg hover:bg-gray-100 transition text-center"
                >
                  Apply for Admission
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
      </div>
      <div className="p-2 mt-auto bg-blue-600 text-lg">
        <Marque className="text-white" direction="right">
          {`Welcome to ${InstitutionName}. I hope you have a wonderful experience and stay here!`}
        </Marque>
      </div>
    </div>
  );
};

export default PortalLogin;
