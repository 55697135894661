import React, { useState } from "react";
import Footer from "../../components/Footer";
import useDetails from "../../hooks/useDetails";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import { LuArrowRight } from "react-icons/lu";
import { toast } from "react-toastify";

const Complaint = () => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [successShow, setSuccessShow] = useState(false);
  const { data: details } = useDetails();
  const navigate = useNavigate();

  const { data } = useDetails();

  const sendComplaint = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate email before proceeding
    const email = details?.email;
    // if (!isValidEmail(email)) {
    //   alert("Invalid email address");
    //   return;
    // }

    const payload = {
      subject,
      message,
      name: `${details?.surname || "Anonymous"} ${
        details?.othername || ""
      }`.trim(),
      email,
    };

    try {
      const { data } = await api.post("/complaint/", payload);
      handleComplaintSuccess(data);
    } catch (error) {
      // handleComplaintError(error);
      toast.error("Failed to send your complaint. Try again.");
    } finally {
      setLoading(false);
    }
  };

  // Utility function to validate email
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Success handler
  const handleComplaintSuccess = (data) => {
    toast.success("Your complaint has been sent successfully!");
    setSuccessShow(true);
    resetForm();
  };

  // Reset form state
  const resetForm = () => {
    setMessage("");
    setSubject("");
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-brand-darkGray">
            Complaint Management
          </h1>
          <p className="text-sm text-gray-400">Make a complaint</p>
        </div>
      </div>
      <div className="mt-6 card-body rounded-xl bg-white space-y-10">
        <h5 className="text-lg font-semibold text-brand-darkGray">
          &#x2022; Complaint
        </h5>

        <form className="w-full space-y-5">
          {/* Subject Field */}
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Subject
              </label>
              <input
                type="text"
                placeholder="Enter subject of your complaint"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                className="text-black font-semibold w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
              />
            </div>
          </div>

          {/* Message Field */}
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Message
              </label>
              <textarea
                placeholder="Provide details of your complaint"
                value={message}
                required
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="text-black font-semibold w-full p-3 border border-gray-300 rounded-md resize-y focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                rows="4"
              ></textarea>
            </div>
          </div>
        </form>
        <div className="flex md:justify-end">
          <button
            className="bg-[#5D71C0] py-2 px-3 flex items-center gap-4 md:gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
            onClick={sendComplaint}
          >
            <p className="text-sm font-medium text-white">Send</p>
            <div className="size-9  rounded-lg flex justify-center items-center cursor-pointer group">
              <LuArrowRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default Complaint;
