import { useEffect, useState } from "react";
import api from "../api/api";
import "./MarqueeStyle.css";

const NewsFeed = () => {
  const [news, setNews] = useState();

  const getNews = async () => {
    try {
      const data = api
        .get("/setup/announcement/view")
        .then((res) => setNews(res.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    getNews();
  }, []);
  return (
    <div className="card-body bg-white rounded-xl space-y-4">
      {/* <div className="marquee-container blur">
        <div className="marquee-slider">
          {news?.map((item) => (
          <h6 key={item.id} className="text-danger">
            {item.body}
          </h6>
          ))}
        </div>
      </div> */}
      <h5 className="text-lg font-semibold text-brand-darkGray">
        &#x2022; News Feed
      </h5>
      <div className="flex flex-col md:flex-row gap-2">
        {newsContent.map((feed, index)=> (
          <div key={index} className="bg-[#FBFBFB] border border-[#ECECEC80] px-4 py-5 rounded-xl space-y-2">
            <h1 className="text-xl font-bold text-brand-black">{feed.title}</h1>
            <p className="text-sm font-normal text-gray-500">{feed.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsFeed;

const newsContent = [
  {
    title: "Senate announces new lecture timetable",
    content:
      "Senate announces new lecture timetable for undergraduate students and post-graduate students. This timetable will take effect from 24th of December 2024, and will include a revised version of activity schedule, as advised by the tim...",
  },
  {
    title: "New Vice Chancellor announced!",
    content:
      "Senate announces new lecture timetable for undergraduate students and post-graduate students. This timetable will take effect from 24th of December 2024, and will include a revised version of activity schedule, as advised by the tim...",
  },
  {
    title: "Exam preparation tips and advice",
    content:
      "Senate announces new lecture timetable for undergraduate students and post-graduate students. This timetable will take effect from 24th of December 2024, and will include a revised version of activity schedule, as advised by the tim...",
  },
];
