import React, { useEffect, useState } from "react";
import { LuArrowUpRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import api from "../api/api";

const CourseRegCard = ({ title, stage }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [registeredCourses, setRegisteredCourses] = useState([]);

  const getRegisteredCourses = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/courses/student-view`);
      setRegisteredCourses(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRegisteredCourses();
  }, []);

  return (
    <div class="w-full p-3 h-[237px] bg-[#DE222212] border border-[#EECDCD80] rounded-xl">
      <div class="">
        {!loading ? (
          <div class="space-y-6">
            <p class="text-[#805050] font-weight-semibold font-14">{title}</p>
            <h1 className="text-4xl font-semibold text-brand-black">
              {registeredCourses?.length > 0 ? "Registered" : "Not done"}
            </h1>
            {registeredCourses?.length > 0 ? (
              <div
                className="bg-white/45 p-2 flex items-center justify-between border border-[#E2D49A80] rounded-xl cursor-pointer hover:scale-105 transition-all ease-in-out"
                onClick={() =>
                  navigate(
                    "/course-reg/print"
                  )
                }
              >
                <p className="text-sm font-medium text-black">
                  Print course form
                </p>
                <div className="size-9 bg-[#F06565E5] rounded-lg flex justify-center items-center cursor-pointer group">
                  <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
                </div>
              </div>
            ) : (
              <div
                className="bg-white/45 p-2 flex items-center justify-between border border-[#E2D49A80] rounded-xl cursor-pointer hover:scale-105 transition-all ease-in-out"
                onClick={() => navigate("/course-reg")}
              >
                <p className="text-sm font-medium text-black">
                  Go to Registration page
                </p>
                <div className="size-9 bg-[#F06565E5] rounded-lg flex justify-center items-center cursor-pointer group">
                  <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <h1 className="text-4xl font-semibold text-brand-black mt-6">
            Loading...
          </h1>
        )}
      </div>
    </div>
  );
};

export default CourseRegCard;
