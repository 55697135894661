import { User, CreditCard } from "react-feather";
import { useNavigate } from "react-router";
import usePayments from "../hooks/usePayments";
import { VerifyPayments } from "../api/student";
import { useState } from "react";
import { toast } from "react-toastify";
import useDetails from "../hooks/useDetails";
import { LuArrowUpRight } from "react-icons/lu";
const SmallCard = ({ title, paymentStatus, invoice }) => {
  const { data: student } = useDetails();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  console.log(paymentStatus, "Omohhh");

  const nullCategories = ["", null]

  const paymentRequery = async (ref) => {
    if (!ref) return alert("No Payment Reference Found");
    try {
      setLoading(true);
      toast.loading("Requering in Progress");
      const response = await VerifyPayments(ref);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Verification Failed");
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`w-full p-3 rounded-xl h-[237px] border border-[#CED6F580] ${nullCategories.includes(student?.payment_complete) ? "bg-[#F0EFFF]":"bg-[#9AEBA3]"}`}
      >
        <div className="">
          {!student ? 
          <h1 className="text-4xl font-semibold text-brand-black mt-6">Loading...</h1>
          :
          <div className="space-y-6">
            <p className="text-dark font-weight-semibold font-14">{title}</p>
            <div className="space-y-1">
              <h1 className="text-4xl font-semibold text-brand-black">
                {nullCategories.includes(student?.payment_complete)
                  ? "Not Paid"
                  : student?.payment_complete.toLowerCase() === "full"
                  ? "Paid"
                  : student?.payment_complete.toLowerCase() === "part"
                  ? "Paid"
                  : "Not Paid"}
              </h1>
              <h6>
                {nullCategories.includes(student?.payment_complete)
                  ? ""
                  : student?.payment_complete.toLowerCase() === "part"
                  ? "(Part Payment)"
                  : student?.payment_complete.toLowerCase() === "full"
                  ? "(Full Payment)"
                  : ""}
              </h6>
            </div>
            {nullCategories.includes(student?.payment_complete) ? (
              <GoToPayment />
            ) : (
              <GoToPrintout />
            )}
            {paymentStatus?.payment_status === "success" &&
            paymentStatus?.paystack_ref ? (
              <button
                class="btn btn-light"
                onClick={() =>
                  navigate("/invoice/school-fee", { state: { invoice } })
                }
              >
                Print Receipt
              </button>
            ) : paymentStatus?.payment_status === "pending" &&
              paymentStatus?.paystack_ref ? (
              <button
                class="btn btn-warning"
                disabled={loading}
                onClick={() => paymentRequery(paymentStatus?.paystack_ref)}
              >
                Requery
              </button>
            ) : null}
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default SmallCard;

const GoToPayment = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white/45 p-2 flex items-center justify-between border border-[#E2D49A80] rounded-xl cursor-pointer hover:scale-105 transition-all ease-in-out"
      onClick={() => navigate("/payments")}
    >
      <p className="text-sm font-medium text-black">Go to payment page</p>
      <div className="size-9 bg-[#506EE4E5] rounded-lg flex justify-center items-center cursor-pointer group">
        <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
      </div>
    </div>
  );
};

const GoToPrintout = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white/45 p-2 flex items-center justify-between border border-[#E2D49A80] rounded-xl cursor-pointer hover:scale-105 transition-all ease-in-out"
      onClick={() => navigate("/payments")}
    >
      <p className="text-sm font-medium text-black">Go to Invoice page</p>
      <div className="size-9 bg-[#506EE4E5] rounded-lg flex justify-center items-center cursor-pointer group">
        <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
      </div>
    </div>
  );
};
