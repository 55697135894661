import React from "react";
import avatar from "../assets/images/user.png";
import { LuPrinter } from "react-icons/lu";

const BiodataSection = ({ data }) => {
  return (
    <div className="flex flex-col md:flex-row gap-5">
      <div className="flex-none w-full md:w-[330px] rounded-xl bg-gray-50 border border-gray-200 flex justify-center">
        <div className="w-full p-4 space-y-6 flex flex-col justify-center items-center">
          <img
            alt="profile_photo"
            src={data?.photo ? data?.photo : avatar}
            className="size-[154px] object-cover rounded-full"
          />
          <div className="text-center space-y-2">
            <p className="text-base font-medium text-brand-black">{`${data?.surname} ${data?.othername}`}</p>
            <p className="text-base font-bold text-brand-blue">{`${data?.matric_no}`}</p>
          </div>
          <div className="text-center space-y-2">
            <p className="text-base font-medium text-gray-400">Student</p>
            <p className="text-base font-medium text-brand-black">{`${data?.level} lvl`}</p>
            <p className="text-base font-medium text-gray-600">
              CGPA: <span className="font-bold">---</span>
            </p>
          </div>
          <button className="flex-none w-full text-sm font-bold px-3 py-2 bg-[#5D71C0] text-white flex items-center justify-between rounded-lg hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out" onClick={() => alert("Printout not available at the moment")}>
            Print Biodata page{" "}
            <div className="size-9 rounded-lg bg-[#151D3B1A] flex items-center justify-center">
              <LuPrinter className="text-white size-[18px]" />
            </div>
          </button>
        </div>
      </div>

      <div className="w-full flex gap-10 border border-gray-200 p-4 rounded-xl">
        <div className="w-full space-y-5">
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Last Name
              </label>
              <input
                type="text"
                defaultValue={data?.surname}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                First Name
              </label>
              <input
                type="text"
                defaultValue={data?.othername}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Matriculation Number
              </label>
              <input
                type="text"
                defaultValue={data?.matric_no}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Application/Registration Number
              </label>
              <input
                type="text"
                defaultValue={data?.application_no}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">Email</label>
              <input
                type="text"
                defaultValue={data?.email}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Phone Number
              </label>
              <input
                type="text"
                defaultValue={data?.phone}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Programme
              </label>
              <input
                type="text"
                defaultValue={data?.programme}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Faculty
              </label>
              <input
                type="text"
                defaultValue={data?.faculty}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Entry Session
              </label>
              <input
                type="text"
                defaultValue={data?.entry_session}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Current Level
              </label>
              <input
                type="text"
                defaultValue={data?.level}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiodataSection;
