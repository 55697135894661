import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import api from "../../../api/api";
import useDetails from "../../../hooks/useDetails";
import { useReactToPrint } from "react-to-print";
import { AffiliateLogo, AffiliateName, InstitutionName, SchoolLogo } from "../../../utils";
const CourseRegPrintout = () => {
  const location = useLocation();
  const session = location?.state || "";

  const [validation, setValidation] = useState();
  const [courses, setCourses] = useState();
  const [level, setLevel] = useState()
  const { data: student } = useDetails();

  const firstSemesterCourses = courses?.filter((course) =>
    ["first", "1"].includes(course.semester.toLowerCase())
  );
  const secondSemesterCourses = courses?.filter((course) =>
    ["second", "2"].includes(course.semester.toLowerCase())
  );

  const totalUnits = courses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const firstSemesterTotalUnits = firstSemesterCourses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );
  const secondSemesterTotalUnits = secondSemesterCourses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 3rem 8rem;
  }
`;
  const printCourseReg = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Course Reg",
    onAfterPrint: () => console.log("Course Reg printed"),
    pageStyle: pageStyle,
  });

  useEffect(() => {
    const validated = async () => {
      try {
        const { data } = await api.get(`/courses/validated/first/100`);
        setValidation(data?.data[0]);
      } catch (error) {}
    };

    validated();
  }, [student]);

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/student-view?session=${session}`
      );
      setCourses(data);
      setLevel(data[0].registration_level)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegisteredCourses();
  }, []);

  if (courses?.length === 0) {
    return (
      <Navigate
        to={{
          pathname: `/course-reg`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div className="">
      <div className="">
        <div className="container-fluid">
          <div className="card" ref={componentRef}>
            <div className="receipt-header">
              <div className="school-title">
                <img src={SchoolLogo} alt="DSP" className="logo" />
                <h3 className="name">{InstitutionName}</h3>
              </div>
              <h6 className="text-center">In Affiliation with</h6>
              <div className="school-title">
                <img src={AffiliateLogo} alt="DSP" className="logo" />
                <h3 className="name">{AffiliateName}</h3>
              </div>
              <br/>
              <div style={{borderBottom:'2px solid #0E0E0E'}}></div>
              <br/>
              <div className="py-1" />
              <h4 className="subheader">Course registration printout</h4>
            </div>
            {firstSemesterCourses?.length > 0 && (
              <div className="p-5">
                <div className="">
                  <table id="mytable" style={{ fontSize: "18px" }}>
                    <tbody>
                      <tr>
                        <th rowSpan={10}>
                          <img
                            alt="passport"
                            src={student?.photo}
                            style={{ width: "180px" }}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>{student?.surname + " " + student?.othername}</td>
                      </tr>
                      <tr>
                        <th>Matriculation Number</th>
                        <td>{student?.matric_no}</td>
                      </tr>
                      <tr>
                        <th>Department</th>
                        <td>{student?.department}</td>
                      </tr>
                      <tr>
                        <th>School/Faculty</th>
                        <td>Education</td>
                      </tr>
                  <tr>
                    <th>Level</th>
                    <td>{level}</td>
                  </tr>
                  <tr>
                    <th>Programme</th>
                    <td>{student?.programme}</td>
                  </tr>
                  <tr>
                    <th>Session</th>
                    <td>{session}</td>
                  </tr>
                  <tr>
                    <th>Entry</th>
                    <td>{student?.entry_session}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{student?.phone}</td>
                  </tr>
                    </tbody>
                  </table>
                </div>

                <h3 className="mt-5 text-center text-lg font-bold mb-2">
                  First Semester
                </h3>
                <table style={{ fontSize: "18px" }}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Course</th>
                      <th>Unit</th>
                      <th>Semester</th>
                    </tr>
                  </thead>
                  <tbody>
                    {firstSemesterCourses?.length > 0 && (
                      <>
                        {firstSemesterCourses?.map(
                          ({ id, code, semester, title, unit }) => (
                            <tr key={id}>
                              <td>{code}</td>
                              <td>{title}</td>
                              <td>{unit}</td>
                              <td>{semester}</td>
                            </tr>
                          )
                        )}

                        <tr>
                          <td></td>
                          <td></td>
                          <th style={{ fontWeight: "700", fontSize: "26px" }}>
                            {firstSemesterTotalUnits}
                          </th>
                          <td></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
                {/* <div className="d-flex">
                  <h4 className="mr-1 text-base font-medium">I</h4>
                  <div className="w-25 line2 mt-4 mr-1"></div>
                  <h4 className="text-base font-medium">
                    do hereby declare that the above information
                    is true and correct
                  </h4>
                </div> */}

                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                    fontSize: "20px",
                    marginTop: "4rem",
                  }}
                >
                  <thead style={{ border: "none" }}>
                    <td style={{ border: "none", width: "15em" }}></td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Name
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Rank
                    </td>
                    <td
                      style={{
                        border: "none",
                        textAlign: "center",
                        width: "10em",
                      }}
                    >
                      Signature
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Date
                    </td>
                  </thead>
                  <tbody>
                    <tr style={{ borderSpacing: "2rem" }}>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Head of Department
                      </th>
                      <td style={{ border: "none", padding: "2rem" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Dean of Faculty
                      </th>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Programme Director
                      </th>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Student
                      </th>
                      <td style={{ border: "none" }}>
                        {/* <div className="line2 mt-4"></div> */}
                      </td>
                      <td style={{ border: "none" }}>
                        {/* <div className="line2 mt-4"></div> */}
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {secondSemesterCourses?.length > 0 && (
              <div className="p-5">
                <div
                  style={{
                    pageBreakBefore:
                      firstSemesterCourses.length > 0 ? "always" : "",
                  }}
                ></div>
                <div className="">
                  <table id="mytable" style={{ fontSize: "18px" }}>
                    <tbody>
                      <tr>
                        <th rowSpan={10}>
                          <img
                            alt="passport"
                            src={student?.photo}
                            style={{ width: "180px" }}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>{student?.surname + " " + student?.othername}</td>
                      </tr>
                      <tr>
                        <th>Matriculation Number</th>
                        <td>{student?.matric_no}</td>
                      </tr>
                      <tr>
                        <th>Department</th>
                        <td>{student?.department}</td>
                      </tr>
                      <tr>
                        <th>School/Faculty</th>
                        <td>Education</td>
                      </tr>
                  <tr>
                    <th>Level</th>
                    <td>{level}</td>
                  </tr>
                  <tr>
                    <th>Programme</th>
                    <td>{student?.programme}</td>
                  </tr>
                  <tr>
                    <th>Session</th>
                    <td>{session}</td>
                  </tr>
                  <tr>
                    <th>Entry</th>
                    <td>{student?.entry_session}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{student?.phone}</td>
                  </tr>
                    </tbody>
                  </table>
                </div>
                <h2 className="d-flex justify-content-center mt-5 text-lg font-bold mb-2">
                  Second Semester
                </h2>
                <table style={{ fontSize: "18px" }}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Course</th>
                      <th>Unit</th>
                      <th>Semester</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {secondSemesterCourses?.map(
                        ({ id, code, semester, title, unit }) => (
                          <tr key={id}>
                            <td>{code}</td>
                            <td>{title}</td>
                            <td>{unit}</td>
                            <td>{semester}</td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td></td>
                        <td></td>
                        <th style={{ fontWeight: "700", fontSize: "26px" }}>
                          {secondSemesterTotalUnits}
                        </th>
                        <td></td>
                        <td></td>
                      </tr>
                    </>
                  </tbody>
                </table>
                <br />
                <br />
                {/* <div className="d-flex">
                  <h4 className="mr-1 text-base font-medium">I</h4>
                  <div className="w-25 line2 mt-4 mr-1"></div>
                  <h4 className="text-base font-medium">
                    do hereby declare that the above information
                    is true and correct
                  </h4>
                </div> */}

                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                    fontSize: "20px",
                    marginTop: "4rem",
                  }}
                >
                  <thead style={{ border: "none" }}>
                    <td style={{ border: "none", width: "15em" }}></td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Name
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Rank
                    </td>
                    <td
                      style={{
                        border: "none",
                        textAlign: "center",
                        width: "10em",
                      }}
                    >
                      Signature
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Date
                    </td>
                  </thead>
                  <tbody>
                    <tr style={{ borderSpacing: "2rem" }}>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Head of Department
                      </th>
                      <td style={{ border: "none", padding: "2rem" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Dean of Faculty
                      </th>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Programme Director
                      </th>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ border: "none", padding: "2rem" }}>
                        Student
                      </th>
                      <td style={{ border: "none" }}>
                        {/* <div className="line2 mt-4"></div> */}
                      </td>
                      <td style={{ border: "none" }}>
                        {/* <div className="line2 mt-4"></div> */}
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="card">
            <button className="btn dspg-danger" onClick={printCourseReg}>
              Print file
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegPrintout;
