import React from "react";
import avatar from "../assets/images/user.png";
import { LuArrowUpRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const BiodataCard = ({ data }) => {
  console.log("biodata", data);
  const navigate = useNavigate();
  return (
    <div className="basis-2/5 bg-white/80 rounded-xl p-3">
      <div className="p-2 bg-gray-100 rounded-lg">
        <div className="flex items-center justify-center gap-3">
          <img
            alt="profile_photo"
            src={data?.photo ? data?.photo : avatar}
            className="size-[30px] object-contain rounded-full"
          />
          <p className="text-sm font-medium text-black">{`${data?.surname} ${data?.othername}`}</p>
        </div>
        <p className="mt-2 text-center text-sm font-medium text-gray-400">
          Matriculation Number:{" "}
          <span className="text-brand-blue">{data?.matric_no}</span>
        </p>
        <div className="mt-4 space-y-2 px-6">
          <p className="text-base font-medium text-gray-400">
            Course:{" "}
            <span className="text-brand-darkGray capitalize">
              {data?.department}
            </span>
          </p>
          <p className="text-base font-medium text-gray-400">
            School:{" "}
            <span className="text-brand-darkGray capitalize">
              {data?.faculty}
            </span>
          </p>
          <p className="text-base font-medium text-gray-400">
            Entry Mode:{" "}
            <span className="text-brand-darkGray capitalize">
              {data?.entry_mode}
            </span>
          </p>
        </div>
      </div>
      <div
        className="mt-6 bg-brand-blue py-2 px-3 flex items-center justify-between border border-[#E2D49A80] rounded-xl cursor-pointer hover:scale-105 transition-all ease-in-out"
        onClick={() => navigate("/biodata")}
      >
        <p className="text-sm font-medium text-white">Go to Profile page</p>
        <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
          <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
        </div>
      </div>
    </div>
  );
};

export default BiodataCard;
