import React from "react";
import { LuArrowUpRight } from "react-icons/lu";

const AlertComponent = () => {
  return (
    <div className="bg-[#FFF2B8] p-3 flex items-center justify-between border border-[#E2D49A80] rounded-xl">
      <p className="text-base font-semibold text-[#716841]">
        Kindly update your biodata
      </p>
      <div className="size-9 bg-[#BFA53373] rounded-lg flex justify-center items-center cursor-pointer group">
        <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
      </div>
    </div>
  );
};

export default AlertComponent;
