import React, { useEffect, useState } from "react";
import useDetails from "../../hooks/useDetails";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import api from "../../api/api";
import { LuBell } from "react-icons/lu";

const Library = () => {
  const [loading, setLoading] = useState();
  const [library, setLibrary] = useState();
  const { data: details } = useDetails();

  // $(function() {
  //     $('#datatable2').DataTable();
  //   })

  const getLibrary = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/library/");
      console.log("Library", data?.results);
      setLibrary(data?.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    $("#dataTable2").DataTable();
  }, [library]);

  useEffect(() => {
    getLibrary();
  }, []);

  return (
    <div class="">
      {details && (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h1 className="text-2xl font-bold text-brand-darkGray">
                Library
              </h1>
              <p className="text-sm text-gray-400">
                Explore your e-library content.
              </p>
            </div>

            <div className="hidden lg:flex items-center gap-2.5">
              <input
                placeholder="search"
                className="w-[330px] py-3 pl-3 text-sm rounded-lg"
              />
              <div className="size-12 bg-white rounded-lg flex items-center justify-center">
                <LuBell className="text-gray-400 size-4" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl card-body">
            <div className="space-y-4">
              <h5 className="text-lg font-semibold text-brand-darkGray">
                &#x2022; Library Resources
              </h5>

              <div className="flex flex-col md:flex-row gap-2">
                {libraryContent.map((data, index) => (
                  <div
                    key={index}
                    className="bg-[#FBFBFB] border border-[#ECECEC80] px-4 py-5 rounded-xl space-y-2"
                  >
                    <h1 className="text-xl font-bold text-brand-black">
                      {data.title}
                    </h1>
                    <p className="text-sm font-normal text-gray-500">
                      {data.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Library;

const libraryContent = [
  {
    title: "e-Books",
    content:
      "List of subscribed full-text e-books",
  },
  {
    title: "e-Journals",
    content:
      "List of subscribed full-text e-journals.",
  },
  {
    title: "Online Databases",
    content:
      "List of subscribed databases.",
  },
  {
    title: "Learning Resource Repository",
    content:
      "Archives of students’ theses, dissertations and projects.",
  },
];
