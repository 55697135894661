import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  GeneralPaymentDetails,
  InitiateSchoolFeePayment,
} from "../../api/student";
import SchoolFeeComponent from "../../components/SchoolFeeComponent";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import usePayments from "../../hooks/usePayments";
import api from "../../api/api";
import { LuArrowUpRight, LuArrowRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import useActiveSession from "../../hooks/useActiveSession";
import { paymentTypes } from "../../utils";

const OtherFees = () => {
  const user = getCurrentUser();
  const [schoolFee, setSchoolFee] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentLevel, setPaymentLevel] = useState("");
  const [paymentSession, setPaymentSession] = useState("");
  const [paidSchoolFee, setPaidSchoolFee] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [btnText, setBtnText] = useState("Proceed to payment");
  const navigate = useNavigate();
  const { session } = useActiveSession();
  const { data } = useDetails();
  const { payment, schoolFeesPayment } = usePayments();

  const getPaymentDetails = async () => {
    if (!paymentType || !paymentLevel || !paymentSession) return;
    try {
      const { data } = await api.post("/student/generate-payment", {
        payment_name: "schoolfees",
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      if (data.status === "error") {
        toast.error(data.message);
        setPaymentSession("");
        setShowAmount(false);
      } else {
        setSchoolFee(data.data);
        setShowAmount(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Fetching payment details failed!");
    }
  };

  const paySchoolFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateSchoolFeePayment({
        student_id: user,
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [paymentType, paymentLevel, paymentSession]);

  useEffect(() => {
    if (schoolFeesPayment && session) {
      // Check if any payment matches the active session
      const hasPaid = schoolFeesPayment.some(
        (payment) => payment.payment_session === session.name
      );
      setPaidSchoolFee(hasPaid);
    }
  }, [schoolFeesPayment, session]);
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h1 className="text-2xl font-bold text-brand-darkGray">
              Other Fees
            </h1>
            <p className="text-sm text-gray-400">View all payments</p>
          </div>

          <div className="flex gap-3">
            <button
              className="bg-[#5D71C0] py-2 px-3 flex items-center gap-4 md:gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
              onClick={() => navigate("/payments")}
            >
              <p className="text-sm font-medium text-white">Invoice</p>
              <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
                <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
              </div>
            </button>
          </div>
        </div>

        <div className="mt-6 card-body rounded-xl bg-white space-y-10">
          <h5 className="text-lg font-semibold text-brand-darkGray">
            &#x2022; Student information
          </h5>

          <div className="w-full space-y-5">
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="w-full">
                <label className="text-sm font-medium text-gray-600">
                  Matriculation Number
                </label>
                <input
                  type="text"
                  defaultValue={data?.matric_no}
                  className="form-control text-black font-semibold"
                  disabled
                />
              </div>
              <div className="w-full">
                <label className="text-sm font-medium text-gray-600">
                  Academic Session
                </label>
                <input
                  type="text"
                  defaultValue={session?.name}
                  className="form-control text-black font-semibold"
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="w-full">
                <label className="text-sm font-medium text-gray-600">
                  Faculty
                </label>
                <input
                  type="text"
                  defaultValue={data?.faculty}
                  className="form-control text-black font-semibold"
                  disabled
                />
              </div>
              <div className="w-full">
                <label className="text-sm font-medium text-gray-600">
                  Department
                </label>
                <input
                  type="text"
                  defaultValue={data?.department}
                  className="form-control text-black font-semibold"
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="w-full lg:w-[49.3%]">
                <label className="text-sm font-medium text-gray-600">
                  Fee Payment
                </label>
                <input
                  type="text"
                  value={paidSchoolFee ? "Paid" : "Not Paid"}
                  className="form-control text-black font-semibold"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-6 bg-white rounded-xl shadow-md mt-6 card-body  space-y-10 overflow-hidden">
          <div className="">
            <h2 class="text-lg font-semibold mb-4"> &#x2022; Payment</h2>
          </div>

          <div class="overflow-x-auto  flex items-center justify-between gap-3">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Select the payment you want to make
              </label>
              <div className="w-full lg:w-[56%]">
                <select
                  className="form-control text-gray-600 font-semibold bg-slate-100"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <option value="">{`--Select--`}</option>
                  {paymentTypes.map((option, index) => (
                    <option key={index} value={option.key}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <label className="text-sm font-medium text-gray-600 mt-4">
                Select the level
              </label>
              <div className="w-full lg:w-[56%]">
                <select
                  className="form-control text-gray-600 font-semibold bg-slate-100"
                  value={paymentLevel}
                  onChange={(e) => setPaymentLevel(e.target.value)}
                >
                  <option value="">{`--Select--`}</option>
                  {["100", "200", "300", "400", "500"].map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <label className="text-sm font-medium text-gray-600 mt-4">
                Select the session
              </label>
              <div className="w-full lg:w-[56%] ">
                <select
                  className="form-control text-gray-600 font-semibold bg-slate-100"
                  value={paymentSession}
                  onChange={(e) => setPaymentSession(e.target.value)}
                >
                  <option value="">{`--Select--`}</option>
                  {[
                    "2019/2020",
                    "2020/2021",
                    "2021/2022",
                    "2022/2023",
                    "2023/2024",
                    "2024/2025",
                  ].map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {showAmount && (
            <div className="flex flex-col">
              <div className="bg-blue-50 px-4 py-8 rounded-md text-4xl">
                {Number(schoolFee?.amount).toLocaleString("en-NG", {style:"currency", currency:"NGN"})}
              </div>
              <div className="flex pt-4">
                <button
                  className="bg-[#5D71C0] py-2 px-3 flex items-center gap-4 md:gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
                  onClick={() => paySchoolFee()}
                >
                  <p className="text-sm font-medium text-white">{btnText}</p>
                  <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
                    <LuArrowRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default OtherFees;
