import SchoolLogo from "../assets/images/DSCE.png";
import AffiliateLogo from "../assets/images/IAUE.png";

export const InstitutionName = "Delta State College of Education, Mosogar";
export const AffiliateName =
  "Ignatius Ajuru University of Education, Port Harcourt";

export { SchoolLogo, AffiliateLogo };

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const paymentTypes = [
  {
    name: "School Fees - Full Payment",
    key: "full",
  },
  {
    name: "School Fees - First Instalment",
    key: "part",
  },
  {
    name: "School Fees - Second Instalment",
    key: "second_installment",
  },
  {
    name: "Late Payment",
    key: "late",
  },
];
