import React, { useEffect, useState } from "react";
import avatar from "../../assets/images/user.png";
import { Info } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import { GetStudentDetails } from "../../api/student";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import NewsFeed from "../../components/NewsFeed";
import SmallCard from "../../components/SmallCard";
import usePayments from "../../hooks/usePayments";
import CourseRegCard from "../../components/CourseRegCard";
import HostelSmallCard from "../../components/HostelSmallCard";
import { LuArrowUpRight, LuBell } from "react-icons/lu";
import AlertComponent from "../../components/AlertComponent";
import BiodataCard from "../../components/BiodataCard";
import { InstitutionName } from "../../utils";

function Dashboard() {
  const user = getCurrentUser();
  const [passport, setPassport] = useState();
  const [details, setDetails] = useState([]);
  const [courseDetails, setCourseDetails] = useState();

  const { data: dashboard } = useDetails();
  const { payment } = usePayments();
  const schoolFee = payment?.schoolFeesPayment;
  const hostelFee = payment?.hostel_fees_payment;

  const location = useLocation();
  const navigate = useNavigate();

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const getDetails = async () => {
    try {
      const data = await GetStudentDetails(user);
      console.log(data, "dataaaa");
      setPassport(data.photo);
      // setStage(data.stage);
      setDetails(data);
      setCourseDetails(data.admin_response[0].approved_program);
    } catch (error) {}
  };

  useEffect(() => {
    getDetails();
  }, []);

  console.log("Dashboard info", dashboard);

  return (
    <div className="">
      {dashboard && details && (
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h1 className="text-2xl font-bold text-brand-darkGray">
                Welcome!👋
              </h1>
              <p className="text-sm text-gray-400">
                Great to have you here with us
              </p>
            </div>
            <div className="hidden lg:flex items-center gap-2.5">
              <input
                placeholder="search"
                className="w-[330px] py-3 pl-3 text-sm rounded-lg"
              />
              <div className="size-12 bg-white rounded-lg flex items-center justify-center">
                <LuBell className="text-gray-400 size-4" />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3">
            <div className="basis-3/5 space-y-2">
              <AlertComponent />
              <div className="flex flex-col md:flex-row gap-3">
                <SmallCard
                  title="School Fee"
                  paymentStatus={schoolFee}
                  invoice={schoolFee}
                />
                <CourseRegCard title="Course Registration" />
              </div>
            </div>
            {/*Biodata card */}
            <BiodataCard data={dashboard} />
          </div>
          <div className="w-full bg-white rounded-xl">
            <div className="">
              <div className="card-body space-y-4">
                <h5 className="text-lg font-semibold text-brand-darkGray">
                  &#x2022; Stand out information
                </h5>
                <p className="text-base text-gray-500 font-medium">
                  {`Welcome to the official portal of ${InstitutionName}.`} 
                </p>
              </div>
            </div>
          </div>
          <NewsFeed />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
