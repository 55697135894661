import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  GeneralPaymentDetails,
  InitiateSchoolFeePayment,
  VerifyPayments,
} from "../../api/student";
import SchoolFeeComponent from "../../components/SchoolFeeComponent";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import usePayments from "../../hooks/usePayments";
import api from "../../api/api";
import { LuArrowUpRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import useActiveSession from "../../hooks/useActiveSession";

const SchoolFees = () => {
  const user = getCurrentUser();
  const [schoolFee, setSchoolFee] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentLevel, setPaymentLevel] = useState("");
  const [paymentSession, setPaymentSession] = useState("");
  const [btnText, setBtnText] = useState("Proceed to payment");
  const { payment, schoolFeesPayment } = usePayments();
  const navigate = useNavigate();
  const { data } = useDetails();
  const { session } = useActiveSession();

  const [currentPage, setCurrentPage] = useState(1);
  const paymentsPerPage = 5;

  const getPaymentDetails = async () => {
    if (!paymentType || !paymentLevel || !paymentSession) return;
    try {
      const { data } = await api.post("/student/generate-payment", {
        payment_name: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      if (data.status === "error") {
        toast.error(data.message);
        setPaymentSession("");
      }
      setSchoolFee(data.data);
    } catch (error) {}
  };

  const paySchoolFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateSchoolFeePayment({
        student_id: user,
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [paymentType, paymentLevel, paymentSession]);

  // Safely handle undefined or invalid schoolFeesPayment
  const totalPages = schoolFeesPayment?.length
    ? Math.ceil(schoolFeesPayment.length / paymentsPerPage)
    : 0;

  // Get the current page's data
  const currentPayments =
    schoolFeesPayment?.length > 0
      ? schoolFeesPayment.slice(
          (currentPage - 1) * paymentsPerPage,
          currentPage * paymentsPerPage
        )
      : [];

  // Handle navigation
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const requeryReceipt = (paymentRef) => {
    paymentRequery(paymentRef);
  };

  const printReceipt = (_data) => {
    navigate("/invoice/school-fee", { state: { _data } });
  };

  const paymentRequery = async (ref) => {
    if (!ref) return alert("No Payment Reference Found");
    try {
      setLoading(true);
      const response = await VerifyPayments(ref);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Verification Failed");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-brand-darkGray">Payments</h1>
          <p className="text-sm text-gray-400">View all payments</p>
        </div>

        <div className="flex gap-3">
          <button
            className="bg-[#5D71C0] py-2 px-3 flex items-center gap-4 md:gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
            onClick={() => navigate("/payments")}
          >
            <p className="text-sm font-medium text-white">Invoice</p>
            <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
              <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
            </div>
          </button>
          <button
            className="bg-[#242D6D] py-2 px-3 flex items-center gap-4 md:gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
            onClick={() => navigate("/payments-otherfees")}
          >
            <p className="text-sm font-medium text-white">Other fees</p>
            <div className="size-9 bg-white rounded-lg flex justify-center items-center cursor-pointer group">
              <LuArrowUpRight className="size-[18px]  text-[#242D6D] group-hover:scale-125 transition-all ease-in-out" />
            </div>
          </button>
        </div>
      </div>

      <div className="mt-6 card-body rounded-xl bg-white space-y-10">
        <h5 className="text-lg font-semibold text-brand-darkGray">
          &#x2022; Student information
        </h5>

        <div className="w-full space-y-5">
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Full name
              </label>
              <input
                type="text"
                placeholder={`${data?.surname}  ${data?.othername}`}
                className="form-control text-black font-semibold placeholder:text-sm placeholder:text-black"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Matriculation Number
              </label>
              <input
                type="text"
                defaultValue={data?.matric_no}
                className="form-control text-black font-semibold capitalize"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Department
              </label>
              <input
                type="text"
                defaultValue={data?.department}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Application Number
              </label>
              <input
                type="text"
                defaultValue={data?.application_no}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Level of Study
              </label>
              <input
                type="text"
                defaultValue={data?.level}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium text-gray-600">
                Academic Session
              </label>
              <input
                type="text"
                defaultValue={session?.name}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="w-full lg:w-[49.3%]">
              <label className="text-sm font-medium text-gray-600">
                School Programme
              </label>
              <input
                type="text"
                defaultValue={data?.programme}
                className="form-control text-black font-semibold"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-xl shadow-md mt-6 card-body space-y-10 overflow-hidden">
        <h2 className="text-lg font-semibold mb-4"> &#x2022; Invoice</h2>
        <div className="overflow-x-auto">
          <table className="w-full text-left border-collapse border-none">
            <thead>
              <tr>
                <th className="p-4 font-semibold">Payment Type</th>
                <th className="p-4 font-semibold">Session</th>
                <th className="p-4 font-semibold">Payment Level</th>
                <th className="p-4 font-semibold">Reference</th>
                <th className="p-4 font-semibold">Instalment</th>
                <th className="p-4 font-semibold">Amount</th>
                <th className="p-4 font-semibold">Date</th>
                <th className="p-4 font-semibold">Status</th>
                <th className="p-4 font-semibold">Action</th>
                {/* <th className="p-4 font-medium">Balance</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Dynamic Rows for Current Page */}
              {currentPayments.map((payment) => (
                <tr key={payment.id} className="hover:bg-gray-50">
                  <td className="p-4">School Fees</td>
                  <td className="p-4">{payment.payment_session}</td>
                  <td className="p-4">{payment.payment_level}</td>
                  <td className="p-4">{payment.paystack_ref}</td>
                  <td className="p-4">
                    {payment.installment == "part"
                      ? "First Instalment"
                      : payment.installment == "second_installment"
                      ? "Second Instalment"
                      : payment.installment}
                  </td>
                  <td className="p-4">
                    ₦{Number(payment.amount_paid).toLocaleString("en-NG")}
                  </td>
                  <td className="p-4">
                    {moment(payment.generated_date).format(
                      "dddd, MMMM Do YYYY, h:mm:ss A"
                    )}
                  </td>
                  <td className="p-4">{payment.payment_status}</td>
                  <td
                    className={`p-4 font-medium hover:cursor-pointer ${
                      payment.payment_status === "success"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                    onClick={
                      payment.payment_status === "success"
                        ? () => printReceipt(payment)
                        : () => requeryReceipt(payment.paystack_ref)
                    }
                  >
                    {payment.payment_status === "success" ? "Print" : "Requery"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex gap-3 items-center mt-4">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-[#5D71C0] text-white hover:bg-[#5D71C0]/90"
            }`}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-[#5D71C0] text-white hover:bg-[#5D71C0]/90"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SchoolFees;
