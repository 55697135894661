import React from "react";
import { LuArrowUpRight, LuPrinter } from "react-icons/lu";
import useDetails from "../../hooks/useDetails";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/user.png";
import BiodataInfo from "../../components/BiodataInfo";
import InputField from "../../components/InputField";

const Biodata = () => {
  const { data } = useDetails();
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-brand-darkGray">Biodata</h1>
          <p className="text-sm text-gray-400">All student information</p>
        </div>

        <button
          className="bg-[#5D71C0] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out"
          onClick={() => navigate("/biodata/profile")}
        >
          <p className="text-sm font-medium text-white">View profile</p>
          <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
            <LuArrowUpRight className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
          </div>
        </button>
      </div>

      <div className="mt-6 card-body rounded-xl bg-white space-y-10">
        <h5 className="text-lg font-semibold text-brand-darkGray">
          &#x2022; Student information
        </h5>

        <BiodataInfo data={data} />

        <div className="w-full space-y-5">
          <div className="flex flex-col lg:flex-row gap-5">
            <InputField label="Gender" value={data?.gender} />
            <InputField label="Country" value={data?.country_origin} />
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <InputField label="State" value={data?.state_origin} />
            <InputField label="LGA" value={data?.lga_origin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biodata;
