import { LuDownload } from "react-icons/lu";
import InputField from "../../components/InputField";
import useDetails from "../../hooks/useDetails";
import { GoFile } from "react-icons/go";
import { useEffect, useState } from "react";
import api from "../../api/api";

const Result = () => {
  const { data } = useDetails();
  const [loading, setLoading] = useState(false)
  const [registeredCourses, setRegisteredCourses] = useState([])

  const getRegisteredCourses = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/courses/student-view`);
      setRegisteredCourses(data);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getRegisteredCourses()
  }, [])
  


  return (
    <>
    {!loading && registeredCourses &&
    
    <div>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold text-brand-darkGray">Result</h1>
          <p className="text-sm text-gray-400">View and download all results</p>
        </div>
        <button className="bg-[#5D71C0] py-2 px-3 flex items-center gap-9 border border-[#E2D49A80] rounded-xl hover:bg-[#5D71C0]/95 hover:scale-105 transition-all ease-in-out" onClick={() => alert("Printout not available at the moment")}>
          <p className="text-sm font-medium text-white">Download PDF</p>
          <div className="size-9 bg-[#1E2F733B] rounded-lg flex justify-center items-center cursor-pointer group">
            <LuDownload className="size-[18px] text-white group-hover:scale-125 transition-all ease-in-out" />
          </div>
        </button>
      </div>

      <div className="mt-6 card-body rounded-xl bg-white space-y-10">
        <h5 className="text-lg font-semibold text-brand-darkGray">
          &#x2022; Student Result
        </h5>

        <div className="w-full space-y-5">
          <div className="flex flex-col lg:flex-row gap-5">
            <InputField
              label="Full Name"
              value={`${data?.surname} ${data?.othername}`}
            />
            <InputField label="Matriculation Number" value={data?.matric_no} />
            <InputField
              label="Application / Registration Number"
              value={data?.application_no}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <InputField label="Faculty" value={data?.faculty} />
            <InputField label="Department" value={data?.department} />
            <InputField label="Course of Study" value={data?.course_of_study} />
          </div>
          <div className="flex flex-col lg:flex-row gap-5">
            <InputField label="Level of Study" value={data?.level} />
            <InputField label="Semester" value={"First"} />
            <InputField label="Session" value={data?.entry_session} />
          </div>
        </div>

        <div className="bg-white rounded-xl card-body border border-[#F1F1F1]">
          <div className="space-y-4">
            <h5 className="text-lg font-semibold text-brand-darkGray">
              &#x2022; Results statement
            </h5>
            {registeredCourses?.length > 0 ?
            <>
              <div className="flex gap-6">
                <p className="text-base font-bold text-brand-black">
                  {data?.level} Level
                </p>
                {/* <p className="text-base font-bold text-brand-darkGray">
                  1st Semester
                </p> */}
              </div>
              <table className="mb-5 bg-gray-">
                <th className="">S/N</th>
                <th className="">Course title</th>
                <th className="">Course code</th>
                <th className="">Unit weight</th>
                <th className="">Status</th>
                <th className="">Grade</th>
                <tbody>
                  {registeredCourses?.map((course, index) => (
                    <tr key={course?.id}>
                      <td>{index + 1}</td>
                      <td>{course?.title}</td>
                      <td className="uppercase">{course?.code}</td>
                      <td>{course?.unit}</td>
                      <td>C</td>
                      <td>{course?.grade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>:
            <EmptyResult />}
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};
export default Result;

const resultSample = [
  {
    id: 1,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
    grade: "A",
  },
  {
    id: 2,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
    grade: "B",
  },
  {
    id: 3,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
    grade: "A",
  },
  {
    id: 4,
    title: "Introduction to advanced business systems",
    code: "BUS401",
    unit: 3,
    grade: "C",
  },
];

const EmptyResult = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 py-20">
      <GoFile className="size-12 text-gray-500" />
      <div className="space-y-2.5 text-center">
        <p className="text-3xl font-semibold text-brand-black">
          Results are being updated
        </p>
        <p className="text-lg font-semibold text-gray-500">
          Kindly remain patient as we upload your results.
        </p>
      </div>
    </div>
  );
};
