import { useEffect, useState } from "react";
import { getCurrentUser } from "../utils/authService";
import api from "../api/api";

const usePayments = () => {
  const [payment, setPayment] = useState({});
  const [schoolFeesPayment, setSchoolFeesPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = getCurrentUser();

  useEffect(() => {
    const getPayments = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const { data } = await api.get(`/student/payment-info/${user}`);
        setPayment(data?.data || {});
        setSchoolFeesPayment(data?.data?.school_fees_payment || []);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setLoading(false);
      }
    };

    getPayments();
  }, [user]);

  return { payment, schoolFeesPayment, loading };
};

export default usePayments;
